<template>
    <section class="">

        <div class="closeSearchBarOnClick"
        @click="setModalMaterial1(false) &&
                setModalMaterial2(false) &&
                setModalMaterial3(false) &&
                setModalMaterial4(false) &&
                setModalMaterial5(false) &&
                setModalMaterial6(false) &&
                setModalMaterial7(false) &&
                setModalMaterial8(false) &&
                setModalMaterial9(false) &&
                setModalMaterial10(false) &&
                setModalMaterial11(false) &&
                setModalMaterial12(false) &&
                setModalMaterial13(false) &&
                setModalMaterial14(false) &&
                setModalMaterial15(false) 
                "
            style="z-index:2!important;position:fixed"></div>
       
        <!-- Primera etapa terminada -->
        <div v-if="userDataBudget === false" class="">

        <!-- Search Bar --> <!-- Search Bar --> <!-- Search Bar --> 
        <!-- Search Bar --> <!-- Search Bar --> <!-- Search Bar --> 

        <div class="closeSearchBarOnClick" @click="modalCity = false; modalProvince = false" style="z-index:2"></div>

        <div class="container mb-12  maxW500 padding20Responsive" v-if="!citySelected">
   
                         <h1 class="title is-4  has-text-centered fontWeigth300 letterSpacing1 font2 mt-2">
                           Buscá tu provincia y ciudad para comenzar
                           <font-awesome-icon class="" :icon="['fa', 'search-location']"  />
                        </h1>
                        <h1 class="title is-5  has-text-centered  fontWeigth300 letterSpacing1 font2">
                           Si tu localidad no aparece es porque no posee corralones registrados
                        </h1>   
                        <div class="searchBarContainer">
                            <div class="mb-0 is-inline-block w100">
                            <p class="floatLeft tituloInputBarraBusqueda">
                              Provincia <span v-show="setProvinceState === false"> | Seleccione de la lista</span>
                            </p>
                            <b-field class="mb-0" :label-position="labelPosition">
                                <b-input
                                    type="text"
                                    placeholder="Buscar provincia..."
                                    custom-class="barraBusqueda"
                                    v-model="province"
                                    autocomplete="off"
                                    @focus="modalProvince = true,modalCity = false"
                                    >
                                </b-input>
                            </b-field>
                            <div v-if="filteredProvinces && modalProvince">
                                <ul class="searchBarTwo">
                                    <li class="listCitys"
                                        v-for="(filteredProvince, index) in filteredProvinces" :key="index"
                                        @click="setProvince(filteredProvince)">{{ filteredProvince.name }}</li>
                                </ul>
                            </div>
                            </div>
                            <div class="mb-0 is-inline-block w100 mt-2">
                            <p class="floatLeft tituloInputBarraBusqueda">
                                 Ciudad <span v-show="setCityState === false"> | Seleccione de la lista</span>
                            </p>
                            <b-field class="mb-0 mt-0" :label-position="labelPosition">
                                <b-input
                                    type="text"
                                    placeholder="Buscar ciudad..."
                                    custom-class="barraBusqueda  lowerCaseInput"
                                    v-model="city"
                                    autocomplete="off"
                                    :disabled="setProvinceState === false"
                                    @focus="modalCity = true,modalProvince = false"
                                    >
                                </b-input>
                            </b-field>    
                            <div v-if="filteredCitys && modalCity">
                                <ul class="searchBarCitys">
                                    <li class="listCitys firstLetter paso1"
                                        v-for="(filteredCity, index) in filteredCitys" :key="index"
                                        @click="setCity(filteredCity)">
                                        
                                         <!-- v-if="filteredCity.farmyardCount > 0" -->
                                            {{ filteredCity.name }} 
                                            <span class="is-hidden">Sin </span> 
                                            <span class="is-hidden colorSuccess fontWeigth700" >{{filteredCity.farmyardCount}}  corralones</span> 
                                           
                                    </li>
                                </ul>
                            </div>
                            </div>
                            
                            <b-button
                                type="is-success"
                                native-type="button"
                                class="searchBarButton title is-5 mt-1 paso1"
                                @click="requestBudget({city, province, emailCorralones, cityId})"
                                >
                                Solicitar presupuesto
                                <font-awesome-icon icon="forward" class="flechaBoton" />
                            </b-button>

                        </div>

        </div>


        <div class="container mb-3 mt-12 maxW500 padding20Responsive" v-if="citySelected">

                         <h1 class="title is-4  has-text-centered fontWeigth300 letterSpacing1 font2 mt-2">
                            Buscá tu provincia y ciudad para comenzar 
                           <font-awesome-icon class="" :icon="['fa', 'search-location']"  />
                        </h1>
                           
                        <div class="searchBarContainer">
                            <b-field class="mb-0 estatica"  label="Buscar provincia"  :label-position="labelPosition">
                                <b-input
                                    type="text"
                                    custom-class="barraBusqueda"
                                    :class="{'SuccessInput' : provinceSelected.length > 3}"
                                    v-model="provinceSelected"
                                    readonly3
                                    disabled
                                    >
                                </b-input>
                            </b-field>
                        </div>  
                         <br>                  
                        <div class="searchBarContainer">
                            <b-field class="mb-0 estatica"  label="Buscar ciudad"  :label-position="labelPosition">
                                <b-input
                                    type="text"
                                    custom-class="barraBusqueda  lowerCaseInput"
                                    :class="{'SuccessInput' : citySelected.length > 3}"
                                    v-model="citySelected"
                                    readonly3
                                    disabled
                                    >
                                </b-input>
                            </b-field>
                        </div>
                        


        </div>




        <!-- Formulario de presupuestos --> <!-- Formulario de presupuestos --> <!-- Formulario de presupuestos --> 
        <!-- Formulario de presupuestos --> <!-- Formulario de presupuestos --> <!-- Formulario de presupuestos --> 

            <div class="columns is-centered mb-12 p-2"  v-if="citySelected">

                <div class="column maxW500 p-0">

                    <form>

                            <!-- Nombre -->
                            <b-field label="Nombre" :label-position="labelPosition">
                                <b-input type="text"
                                placeholder="Nombre y apellido"
                                v-model="$v.name.$model"
                                custom-class="inputRequestBudget"
                                :class="{'SuccessInput' : !$v.name.$invalid, 'NormalInput' : $v.name.$invalid}"
                                 >
                                </b-input>
                            </b-field>                            
                            <p v-if="$v.name.$error === true" class="subtitle is-7 mb-1 colorSoftRed">Campo requerido, debe tener más de 4 caracteres</p>
        
                            <div class="mb-2 is-block"></div>
                    
                            <!-- Email -->
                            <b-field  label="Email"  :label-position="labelPosition">
                                <b-input type="email"
                                    placeholder="Email"
                                    v-model="email"
                                    custom-class="inputRequestBudget"
                                    :class="{'SuccessInput' : !$v.email.$invalid, 'NormalInput' : $v.email.$invalid}"
                                    maxlength="100"
                                    :has-counter="false"
                                  >
                                </b-input>
                            </b-field>
                            <p v-if="$v.email.$error === true" class="subtitle is-7 mt-1 colorSoftRed">Campo requerido, ingrese un email</p>

                            <div class="mb-2 is-block"></div>
      
                            <!-- Telefono -->
                            <b-field label="Telefono" :label-position="labelPosition">
                                <b-input type="text"
                                placeholder="Telefono"
                                v-model="phone"
                                custom-class="inputRequestBudget"
                                :class="{'SuccessInput' : !$v.phone.$invalid, 'NormalInput' : $v.phone.$invalid}"
                                 >
                                </b-input>
                            </b-field>
                            <p v-if="$v.phone.$error === true" class="subtitle is-7 mt-1 colorSoftRed">Campo requerido</p>                            

                            <div class="mb-2 is-block"></div>

                            <!-- Direccion -->
                            <b-field label="Direccion" :label-position="labelPosition">
                                <b-input type="text"
                                placeholder="Direccion"
                                v-model="$v.address.$model" 
                                custom-class="inputRequestBudget"
                                :class="{'SuccessInput' : !$v.address.$invalid, 'NormalInput' : $v.address.$invalid}"
                                 >
                                </b-input>
                            </b-field>        
                            <p v-if="$v.address.$error === true" class="subtitle is-7 mt-1 colorSoftRed">Campo requerido</p>   

                            <div class="mb-2 is-block"></div>


                            <!-- Boton -->
                            <div class="column has-text-centered p-0">
                                
                                      <b-button tag="input"
                                      class="bgColorPrimary has-text-centered colorWhite buttonRequestBudget title is-5 continuarPresupesto"
                                      value="Continuar"
                                      v-if="this.$v.$invalid === false"
                                      @click="userDataBudget = true;goTop()"
                                      ></b-button>
                                      <b-button tag="input"
                                      class="bgColorPrimary has-text-centered colorWhite buttonRequestBudget title is-5"
                                      value=""
                                      v-if="this.$v.$invalid === true"
                                      disabled
                                      >Continuar <font-awesome-icon icon="forward" class="flechaBoton" /></b-button>
                                   
                            </div>  
                               
                    </form>
          
                </div>
            
            </div>
        
        </div>

        <!-- Solicitar materiales --> <!-- Solicitar materiales --> <!-- Solicitar materiales --> 
        <!-- Solicitar materiales --> <!-- Solicitar materiales --> <!-- Solicitar materiales --> 

        <!-- v-if="userDataBudget === true" -->
        <div class="container mb-3 mt-12 maxW500 paddingResponsive padding20Responsive" v-if="userDataBudget === true">
            <div class="mb-12 p-0">

                         <h2 class="title is-4  has-text-centered fontWeigth300 letterSpacing1 font2 mt-2">
                           Datos guardados <font-awesome-icon :icon="['fa', 'check-circle']"  class="colorSuccessTwo"/>
                        </h2>

                        <h3 class="title is-5  has-text-centered fontWeigth300 letterSpacing1 font2 mt-2">
                           ¡Ahora agrega los materiales!
                        </h3>
                        <h3 class="title is-6  has-text-centered fontWeigth300 letterSpacing1 font2 mt-2">
                           Selecciona de la lista o agrega uno
                        </h3>
                
                        <!-- Componentes Input Materials -->
                        <!-- Componentes Input Materials --> 
                
                        <CmpMaterial1 @clickedItem="getMaterial1" @quantity="getQuantity1" class="has-text-centered"/>

                            <button
                            v-if="showInput1 === false"
                            class="mt-2 colorWhite title is-8 botonAgregarQuitarMaterial has-text-centered"
                            @click="showInput1 = true"
                            ><font-awesome-icon class="colorIconMoreInput" :icon="['fa', 'plus-circle']"  /></button>
                        <CmpMaterial2 @clickedItem="getMaterial2" @quantity="getQuantity2" class="has-text-centered"
                        v-if="showInput1 === true"
                        />

                             <button
                            v-if="showInput2 === false && showInput1 === true"
                            class="mt-2 colorWhite title is-8 botonAgregarQuitarMaterial has-text-centered"
                            @click="showInput2 = true"
                            ><font-awesome-icon class="colorIconMoreInput" :icon="['fa', 'plus-circle']"  /></button>
                        <CmpMaterial3 @clickedItem="getMaterial3" @quantity="getQuantity3"  class="has-text-centered"
                        v-if="showInput2 === true"/>

                              <button
                            v-if="showInput3 === false && showInput2 === true"
                            class="mt-2 colorWhite title is-8 botonAgregarQuitarMaterial has-text-centered"
                            @click="showInput3 = true"
                            ><font-awesome-icon class="colorIconMoreInput" :icon="['fa', 'plus-circle']"  /></button>
                        <CmpMaterial4 @clickedItem="getMaterial4" @quantity="getQuantity4"  class="has-text-centered"
                        v-if="showInput3 === true"/>

                           <button
                            v-if="showInput4 === false && showInput3 === true"
                            class="mt-2 colorWhite title is-8 botonAgregarQuitarMaterial has-text-centered"
                            @click="showInput4 = true"
                            ><font-awesome-icon class="colorIconMoreInput" :icon="['fa', 'plus-circle']"  /></button>
                        <CmpMaterial5 @clickedItem="getMaterial5" @quantity="getQuantity5"  class="has-text-centered"
                         v-if="showInput4 === true"/>

                            <button
                            v-if="showInput5 === false && showInput4 === true"
                            class="mt-2 colorWhite title is-8 botonAgregarQuitarMaterial has-text-centered"
                            @click="showInput5 = true"
                            ><font-awesome-icon class="colorIconMoreInput" :icon="['fa', 'plus-circle']"  /></button> 
                        <CmpMaterial6 @clickedItem="getMaterial6" @quantity="getQuantity6"  class="has-text-centered"
                        v-if="showInput5 === true"/>

                            <button
                            v-if="showInput6 === false && showInput5 === true"
                            class="mt-2 colorWhite title is-8 botonAgregarQuitarMaterial has-text-centered"
                            @click="showInput6 = true"
                            ><font-awesome-icon class="colorIconMoreInput" :icon="['fa', 'plus-circle']"  /></button>                   
                        <CmpMaterial7 @clickedItem="getMaterial7" @quantity="getQuantity7"  class="has-text-centered"
                        v-if="showInput6 === true"/>

                            <button
                            v-if="showInput7 === false && showInput6 === true"
                            class="mt-2 colorWhite title is-8 botonAgregarQuitarMaterial has-text-centered"
                            @click="showInput7 = true"
                            ><font-awesome-icon class="colorIconMoreInput" :icon="['fa', 'plus-circle']"  /></button>    
                        <CmpMaterial8 @clickedItem="getMaterial8" @quantity="getQuantity8"  class="has-text-centered"
                        v-if="showInput7 === true"/>

                            <button
                            v-if="showInput8 === false && showInput7 === true"
                            class="mt-2 colorWhite title is-8 botonAgregarQuitarMaterial has-text-centered"
                            @click="showInput8 = true"
                            ><font-awesome-icon class="colorIconMoreInput" :icon="['fa', 'plus-circle']"  /></button>    
                        <CmpMaterial9 @clickedItem="getMaterial9"  @quantity="getQuantity9" class="has-text-centered"
                        v-if="showInput8 === true"/>

                            <button
                            v-if="showInput9 === false && showInput8 === true"
                            class="mt-2 colorWhite title is-8 botonAgregarQuitarMaterial has-text-centered"
                            @click="showInput9 = true"
                            ><font-awesome-icon class="colorIconMoreInput" :icon="['fa', 'plus-circle']"  /></button>    
                        <CmpMaterial10 @clickedItem="getMaterial10"  @quantity="getQuantity10" class="has-text-centered"
                        v-if="showInput9 === true"/>

                            <button
                            v-if="showInput10 === false && showInput9 === true"
                            class="mt-2 colorWhite title is-8 botonAgregarQuitarMaterial has-text-centered"
                            @click="showInput10 = true"
                            ><font-awesome-icon class="colorIconMoreInput" :icon="['fa', 'plus-circle']"  /></button>    
                        <CmpMaterial11 @clickedItem="getMaterial11"  @quantity="getQuantity11"   class="has-text-centered"
                            v-if="showInput10 === true"/>

                            <button
                            v-if="showInput11 === false && showInput10 === true"
                            class="mt-2 colorWhite title is-8 botonAgregarQuitarMaterial has-text-centered"
                            @click="showInput11 = true"
                            ><font-awesome-icon class="colorIconMoreInput" :icon="['fa', 'plus-circle']"  /></button>    
                        <CmpMaterial12 @clickedItem="getMaterial12"   @quantity="getQuantity12"  class="has-text-centered"
                            v-if="showInput11 === true"/>

                            <button
                            v-if="showInput12 === false && showInput11 === true"
                            class="mt-2 colorWhite title is-8 botonAgregarQuitarMaterial has-text-centered"
                            @click="showInput12 = true"
                            ><font-awesome-icon class="colorIconMoreInput" :icon="['fa', 'plus-circle']"  /></button>                            
                        <CmpMaterial13 @clickedItem="getMaterial13"  @quantity="getQuantity13"   class="has-text-centered"
                            v-if="showInput12 === true"/>

                            <button
                            v-if="showInput13 === false && showInput12 === true"
                            class="mt-2 colorWhite title is-8 botonAgregarQuitarMaterial has-text-centered"
                            @click="showInput13 = true"
                            ><font-awesome-icon class="colorIconMoreInput" :icon="['fa', 'plus-circle']"  /></button>                            
                        <CmpMaterial14 @clickedItem="getMaterial14"   @quantity="getQuantity14"  class="has-text-centered"
                            v-if="showInput13 === true"/>

                            <button
                            v-if="showInput14 === false && showInput13 === true"
                            class="mt-2 colorWhite title is-8 botonAgregarQuitarMaterial has-text-centered"
                            @click="showInput14 = true"
                            ><font-awesome-icon class="colorIconMoreInput" :icon="['fa', 'plus-circle']"  /></button>                            
                        <CmpMaterial15 @clickedItem="getMaterial15"  @quantity="getQuantity15"   class="has-text-centered"
                            v-if="showInput14 === true"/>

                        <p v-if="showInput14 === true"
                           class="mt-2 colorSoftRed subtitle is-7 has-text-centered">
                           Alcanzaste el máximo de materiales, podes añadir más en el campo de observaciones.
                        </p>

                        <b-field label="Observaciones (opcional)" class="mt-2 zIndexObservaciones">
                            <b-input maxlength="200" type="textarea" v-model="observaciones"
                                  @focus="setModalMaterial1(false) &&
                setModalMaterial2(false) &&
                setModalMaterial3(false) &&
                setModalMaterial4(false) &&
                setModalMaterial5(false) &&
                setModalMaterial6(false) &&
                setModalMaterial7(false) &&
                setModalMaterial8(false) &&
                setModalMaterial9(false) &&
                setModalMaterial10(false) &&
                setModalMaterial11(false) &&
                setModalMaterial12(false) &&
                setModalMaterial13(false) &&
                setModalMaterial14(false) &&
                setModalMaterial15(false) 
                "
                            ></b-input>
                        </b-field>
                        <p class="colorSoftRed subtitle is-6 mb-2" v-if="errorSent">Error: {{errorSent}}</p>
                                <div class="imgFormMaterials">
                                    <b-checkbox
                                        v-model="receiveBudgetWhatsapp"
                                        true-value="Si"
                                        false-value="No"
                                        style="z-index:1">
                                        <p class="is-inline-block" >Quiero recibir el presupuesto por Whatsapp</p>
                                        <img
                                            src="@/assets/img/whatsapp.png"
                                            alt="Corralon central"
                                            width="25px"
                                            class="is-inline-block"
                                        > 
                                    </b-checkbox>
                                </div>
                        <div class="has-text-centered">
                        <pulse-loader  :loading="loader" :color="colorLoader" v-if="loader2" class="is-inline-block has-text-centered  mt-2"></pulse-loader> 
                        </div>

                        <b-button tag="input"
                        native-type="submit"
                        @click="sendRequestBudget();saveBudgetDB()"
                        v-if="!loader2"
                        class="bgColorPrimary has-text-centered colorWhite buttonRequestBudget title is-5 mt-2 solicitarPresupuesto"
                        value="Solicitar presupuesto"
                        >Solicitar presupuesto</b-button> 
                  
            </div>

        </div>
     
    </section>   
</template>

<script>
import { mapActions, mapState } from "vuex"
import { required, minLength, email, sameAs } from 'vuelidate/lib/validators'
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import router from '@/router'


// Componentes Input Materials
// Componentes Input Materials
import CmpMaterial1 from '@/components/requestBudget/cmpChildren/inputsMaterials/Material1.vue'
import CmpMaterial2 from '@/components/requestBudget/cmpChildren/inputsMaterials/Material2.vue'
import CmpMaterial3 from '@/components/requestBudget/cmpChildren/inputsMaterials/Material3.vue'
import CmpMaterial4 from '@/components/requestBudget/cmpChildren/inputsMaterials/Material4.vue'
import CmpMaterial5 from '@/components/requestBudget/cmpChildren/inputsMaterials/Material5.vue'
import CmpMaterial6 from '@/components/requestBudget/cmpChildren/inputsMaterials/Material6.vue'
import CmpMaterial7 from '@/components/requestBudget/cmpChildren/inputsMaterials/Material7.vue'
import CmpMaterial8 from '@/components/requestBudget/cmpChildren/inputsMaterials/Material8.vue'
import CmpMaterial9 from '@/components/requestBudget/cmpChildren/inputsMaterials/Material9.vue'
import CmpMaterial10 from '@/components/requestBudget/cmpChildren/inputsMaterials/Material10.vue'
import CmpMaterial11 from '@/components/requestBudget/cmpChildren/inputsMaterials/Material11.vue'
import CmpMaterial12 from '@/components/requestBudget/cmpChildren/inputsMaterials/Material12.vue'
import CmpMaterial13 from '@/components/requestBudget/cmpChildren/inputsMaterials/Material13.vue'
import CmpMaterial14 from '@/components/requestBudget/cmpChildren/inputsMaterials/Material14.vue'
import CmpMaterial15 from '@/components/requestBudget/cmpChildren/inputsMaterials/Material15.vue'

export default {
    
    name: 'CmpBudgeter',

    components: {
        PulseLoader,
        CmpMaterial1,
        CmpMaterial2,
        CmpMaterial3,
        CmpMaterial4,
        CmpMaterial5,
        CmpMaterial6,
        CmpMaterial7,
        CmpMaterial8,
        CmpMaterial9,
        CmpMaterial10,
        CmpMaterial11,
        CmpMaterial12,
        CmpMaterial13,
        CmpMaterial14,
        CmpMaterial15
    },

    data() {
        return {
            // Searchbar
            province: '',
            provinces: [],
            provincesId:'',
            filteredProvinces: {name:'name',id:'id'},
            modalProvince: false,
            setProvinceState: false,


            city: '',
            cityId: 0,
            citys: null,
            filteredCitys: [{name}],
            modalCity: false,
            userDataBudget: false,
            setCityState: false,
            cityFarmyardCount: '',

            // Email corralones
            emailCorralones: [],
            arrayEmail: [],

            // Form
            labelPosition: 'on-border',
            show: true,
            name:'',
            email:'',
            phone: '',
            province: '',
            address: '',
            checkbox: false,
            receiveBudgetWhatsapp: 'Si',
            errorSent: '',

            quantity1:'',
            quantity2:'',
            quantity3:'',
            quantity4:'',
            quantity5:'',
            quantity6:'',
            quantity7:'',
            quantity8:'',
            quantity9:'',
            quantity10:'',
            quantity11:'',
            quantity12:'',
            quantity13:'',
            quantity14:'',
            quantity15:'',

            

            // Materiales
            material1:'',
            material2:'',
            material3:'',
            material4:'',
            material5:'',
            material6:'',
            material7:'',
            material8:'',
            material9:'',
            material10:'',
            material11:'',
            material12:'',
            material13:'',
            material14:'',
            material15:'',
            observaciones:'',    


            // Mostrar inputs
            showInput1: false,
            showInput2: false,
            showInput3: false,
            showInput4: false,
            showInput5: false,
            showInput6: false,
            showInput7: false,
            showInput8: false,
            showInput9: false,
            showInput10: false,
            showInput11: false,
            showInput12: false,
            showInput13: false,
            showInput14: false,
            showInput15: false,

        }
    },

    //Validacion con vuevalidate
    validations: {
        email: {
            required,
            email,
            minLength: minLength(4)
        },
        name: {
            required,
            minLength: minLength(4)
        },
        phone: {
            required,
            minLength: minLength(4)
        },
        address: {
            required,
            minLength: minLength(4)
        },

    },

     mounted() {
          this.getProvince();
          this.getCity();   
         // console.log(this.emailCorralones)     

    },


     methods: {
        ...mapActions('sessions',['createUser']), 
        ...mapActions('searchBar',['requestBudget']),
        ...mapActions('materialInputs',
        [ 
        'setModalMaterial1',
        'setModalMaterial2',
        'setModalMaterial3',
        'setModalMaterial4',
        'setModalMaterial5',
        'setModalMaterial6',
        'setModalMaterial7',
        'setModalMaterial8',
        'setModalMaterial9',
        'setModalMaterial10',
        'setModalMaterial11',
        'setModalMaterial12',
        'setModalMaterial13',
        'setModalMaterial14',
        'setModalMaterial15',
        ]
        ),

       goTop() {
             window.scrollTo(0, 0);
        },

        getQuantity1(quantity1) {
            this.quantity1 = quantity1 ;     
        },
        getQuantity2(quantity2) {
            this.quantity2 = quantity2 ;     
        },

        getQuantity3(quantity3) {
            this.quantity3 = quantity3 ;     
        },

        getQuantity4(quantity4) {
            this.quantity4 = quantity4 ;     
        },

        getQuantity5(quantity5) {
            this.quantity5 = quantity5 ;     
        },

        getQuantity6(quantity6) {
            this.quantity6 = quantity6 ;     
        },

        getQuantity7(quantity7) {
            this.quantity7 = quantity7 ;     
        },

        getQuantity8(quantity8) {
            this.quantity8 = quantity8 ;     
        },

        getQuantity9(quantity9) {
            this.quantity9 = quantity9 ;     
        },

        getQuantity10(quantity10) {
            this.quantity10 = quantity10 ;     
        },

        getQuantity11(quantity11) {
            this.quantity11 = quantity11 ;     
        },

        getQuantity12(quantity12) {
            this.quantity12 = quantity12 ;     
        },

        getQuantity13(quantity13) {
            this.quantity13 = quantity13 ;     
        },

        getQuantity14(quantity14) {
            this.quantity14 = quantity14 ;     
        },

        getQuantity15(quantity15) {
            this.quantity15 = quantity15 ;     
        },
        // Igualo materiales
        // Igualo materiales
        getMaterial1(material1) {
            this.material1= material1;     
        },

        getMaterial2(material2) {
            this.material2 = material2;     
        },

        getMaterial3(material3) {
            this.material3 = material3;     
        },
        
        getMaterial4(material4) {
            this.material4 = material4;     
        },
        
        getMaterial5(material5) {
            this.material5 = material5;     
        },
        
        getMaterial6(material6) {
            this.material6 = material6;     
        },
        
        getMaterial7(material7) {
            this.material7 = material7;     
        },
        
        getMaterial8(material8) {
            this.material8 = material8;     
        },
        
        getMaterial9(material9) {
            this.material9 = material9;     
        },
        
        getMaterial10(material10) {
            this.material10 = material10;     
        },
        
        getMaterial11(material11) {
            this.material11 = material11;     
        },
        
        getMaterial12(material12) {
            this.material12 = material12;     
        },
                
        getMaterial13(material13) {
            this.material13 = material13;     
        },
                
        getMaterial14(material14) {
            this.material14 = material14;     
        },
                
        getMaterial15(material15) {
            this.material15 = material15;     
        },

      getProvince(){
            this.axios.get('/provinces/')
            .then(res => {       
                const arrayProvincias = res.data;  
                // Construyo el Array provincia
                this.provinces =  arrayProvincias.map(({ id, name }) => ({ id, name }));
                this.filterProvinces();
            })
            .catch(error => {
                this.provinces = [{name: 'No se encontraron provincias'}]
            })
        },

        filterProvinces(){
    

            if (this.province.length == 0) {
            this.filteredProvinces = this.provinces;
            }

            const provincesNameId = this.provinces;

            // Tengo que averiguar como aplicar un filtro sobre un objeto array
            this.filteredProvinces = provincesNameId.filter(province => {
                    return province.name.toLowerCase().includes(this.province.toLowerCase());
            })

               // console.log('Fuera del filtro')
               // console.log(this.filteredProvincesLowerCase)
           // this.filteredProvinces = this.filteredProvincesLowerCase.slice(0, 6);

        },

        setProvince(province) {
            console.log('• Debajo de esta linea 2')
           console.log(province)
            this.province = province.name;
            this.modalProvince = false;
            this.provincesId = province.id;

            this.setProvinceState = true;
            
            this.getCity();
        },
        

        // Searchbar Searchbar Searchbar Searchbar 
        // Searchbar Searchbar Searchbar Searchbar 
        getCity(){
            console.log('• Debajo de esta linea 1')
            if(this.provincesId != '') {
            this.axios.get('/provinces/'+this.provincesId+'/cities/farmyardCount')
                .then(res => {    
                    const arrayCitys = res.data;  
                    this.citys =  arrayCitys.map(({ name, farmyardCount, id }) => ({ name, farmyardCount, id }));
                     console.log(this.citys)
                    this.filterCitys();
                })
                .catch(error => {
                    this.citys = [{name: 'No se encontraron ciudades'}]
                })
            }  
         
        },
        
        filterCitys() {

            if (this.city.length == 0) {
                this.filteredCitys = this.citys;
            }
             console.log('Filtrado 1')
            console.log(this.citys)

            this.filteredCitys = this.citys.filter(city => {
                return city.name.toLowerCase().includes(this.city.toLowerCase());
            })
             console.log('Filtrado 2')
              console.log(this.filteredCitys)

            //this.filteredCitys = this.filteredCitysLowerCase.slice(0, 6);
        },

       setCity(city) {
            this.city = city.name;
            this.cityId = city.id;
            // Una vez selecciona la ciudad, traigo los Emails
            this.getEmails()
            this.modalCity = false;
            this.setCityState = true;
            this.cityFarmyardCount = city.farmyardCount;
        },

      getEmails(){
            this.axios.get('/cities/'+ this.cityId +'/mails')
                .then(res => {   
               // console.log('Respuesta') 
               // console.log(res.data)
                const arrayEmail = res.data;  
    
                // Construyo el Email
                this.arrayEmail =  arrayEmail.map(({ email }) => ({ email }));
               // console.log('Array email convertido en solo email')
               // console.log(this.arrayEmail.email) 

                this.emailCorralones = this.arrayEmail.map(function(el) {
                    return el.email;
                 });
                  //  console.log(this.emailCorralones)
                })
                .catch(error => {
                    this.citys = [{name: 'No se encontraron ciudades'}]
                })         
        },


        // Form  Form  Form  Form  Form  Form 
        // Form  Form  Form  Form  Form  Form 

            
        saveBudgetDB() {
            
            const name = this.name;
            const email = this.email;
            const observation = this.observaciones;
            const address = this.address
            const cityId = this.cityIdSelected
            const phoneBudget = this.phone

           // console.log('Ciudad' + cityId)

            // Materiales
            const material1 = this.material1
            const material2 = this.material2
            const material3 = this.material3
            const material4 = this.material4
            const material5 = this.material5
            const material6 = this.material6
            const material7 = this.material7
            const material8 = this.material8
            const material9 = this.material9
            const material10 = this.material10
            const material11 = this.material11
            const material12 = this.material12
            const material13 = this.material13
            const material14 = this.material14
            const material15 = this.material15
            
            const quantity1 = this.quantity1
            const quantity2 = this.quantity2
            const quantity3 = this.quantity3
            const quantity4 = this.quantity4
            const quantity5 = this.quantity5
            const quantity6 = this.quantity6
            const quantity7 = this.quantity7
            const quantity8 = this.quantity8
            const quantity9 = this.quantity9
            const quantity10 = this.quantity10
            const quantity11 = this.quantity11
            const quantity12 = this.quantity12
            const quantity13 = this.quantity13
            const quantity14 = this.quantity14
            const quantity15 = this.quantity15

            this.axios.post('/budgets',
            {
                "observation": observation,
                "name": name,
                "email": email,
                "adress": address,
                "phone" : phoneBudget,
                "city": {
                    "id": cityId
                },
                "budgetLineList": [
                        {
                            "product": material1,
                            "quantity": quantity1
                        },
                        {
                            "product": material2,
                            "quantity": quantity2
                        },
                        {
                            "product": material3,
                            "quantity": quantity3
                        },
                        {
                            "product": material4,
                            "quantity": quantity4
                        },
                        {
                            "product": material5,
                            "quantity": quantity5
                        },
                        {
                            "product": material6,
                            "quantity": quantity6
                        },
                        {
                            "product": material7,
                            "quantity": quantity7
                        },
                        {
                            "product": material8,
                            "quantity": quantity8
                        },
                        {
                            "product": material9,
                            "quantity": quantity9
                        },
                        {
                            "product": material10,
                            "quantity": quantity10
                        },
                        {
                            "product": material11,
                            "quantity": quantity11
                        },
                        {
                            "product": material12,
                            "quantity": quantity12
                        },
                        {
                            "product": material13,
                            "quantity": quantity13
                        },
                        {
                            "product": material14,
                            "quantity": quantity14
                        },
                        {
                            "product": material15,
                            "quantity": quantity15
                        }
                ]
            })
            .then(res => { 
               // console.log('Respuesta axios DB')
              //  console.log(res)
            })
            .catch(error => {
              //  console.log(error.response);
            })
        },


        async sendRequestBudget() {

            //Recaptcha
           // await this.$recaptchaLoaded()
           // const token = await this.$recaptcha('submit')
        if(this.material1){

            this.$v.$touch()
            if (this.$v.$invalid) {
        

            } else {
                this.errorSent = ''


            // Axios Axios Axios Axios Axios
            // Axios Axios Axios Axios Axios

            if (!this.observaciones) {
                this.observaciones = null;
            }

            if (!this.material2) {
                this.material2 = 'No se ingresó';
                this.quantity2 = 0;
            }

            if (!this.material3) {
                this.material3 = 'No se ingresó';
                this.quantity3 = 0;
            }

            if (!this.material4) {
                this.material4 = 'No se ingresó';
                this.quantity4 = 0;
            }

            if (!this.material5) {
                this.material5 = 'No se ingresó';
                this.quantity5 = 0;
            }
            
            if (!this.material6) {
                this.material6 = 'No se ingresó';
                this.quantity6 = 0;
            }
            
            if (!this.material7) {
                this.material7 = 'No se ingresó';
                this.quantity7 = 0;
            }
            
            if (!this.material8) {
                this.material8 = 'No se ingresó';
                this.quantity8 = 0;
            }
            
            if (!this.material9) {
                this.material9 = 'No se ingresó';
                this.quantity9 = 0;
            }
            
            if (!this.material10) {
                this.material10 = 'No se ingresó';
                this.quantity10 = 0;
            }
            
            if (!this.material11) {
                this.material11 = 'No se ingresó';
                this.quantity11 = 0;
            }
                        
            if (!this.material12) {
                this.material12 = 'No se ingresó';
                this.quantity12 = 0;
            }
                        
            if (!this.material13) {
                this.material13 = 'No se ingresó';
                this.quantity13 = 0;
            }
                        
            if (!this.material14) {
                this.material14 = 'No se ingresó';
                this.quantity14 = 0;
            }
                        
            if (!this.material15) {
                this.material15 = 'No se ingresó';
                this.quantity15 = 0;
            }
            
                //Loader
                this.$store.commit('generalFunctions/loaderFirebase2', true); 

                this.axios.post("https://corraloncentral.com.ar/send-email-budget.php", {

                                // SearchBar
                                city: this.citySelected,    

                                // Form
                                email: this.email,
                                name: this.name,
                                phone: this.phone,
                                province: this.provinceSelected,
                                address: this.address,
                                receiveBudgetWhatsapp: this.receiveBudgetWhatsapp,
                                //token: token,

                                // Email corralones
                                emailCorralones: this.emailCorralonesSelected,
                                
                                // Materiales
                                material1: this.material1,
                                material2: this.material2,
                                material3: this.material3,
                                material4: this.material4,
                                material5: this.material5,
                                material6: this.material6,
                                material7: this.material7,
                                material8: this.material8,
                                material9: this.material9,
                                material10: this.material10,
                                material11: this.material11,
                                material12: this.material12,
                                material13: this.material13,
                                material14: this.material14,
                                material15: this.material15,
                                
                                quantity1: this.quantity1,
                                quantity2: this.quantity2,
                                quantity3: this.quantity3,
                                quantity4: this.quantity4,
                                quantity5: this.quantity5,
                                quantity6: this.quantity6,
                                quantity7: this.quantity7,
                                quantity8: this.quantity8,
                                quantity9: this.quantity9,
                                quantity10: this.quantity10,
                                quantity11: this.quantity11,
                                quantity12: this.quantity12,
                                quantity13: this.quantity13,
                                quantity14: this.quantity14,
                                quantity15: this.quantity15,

                                observaciones: this.observaciones,  

                    })
                    
                    .then(res => {
                       console.log('Eamils 😀😀😀😀😀😀')
                       console.log(res)

                        // Route
                        this.$store.commit('generalFunctions/setFromtRoute', this.$route.name); 
                        
                        // Loader
                        this.$store.commit('generalFunctions/loaderFirebase2', false); 

                        router.push({name: 'VThanks'}).catch(err => {});
                        this.sent = true;
                    })

                    .catch(error => {
                      //  console.log(error.response)
                    })

                }
            } else {
                this.errorSent = 'Agregue al menos un material'
            }
        },

     },

    watch: {
        city() {
            this.filterCitys();
        },
        province() {
           // console.log('Se ejecuta filtro provincias 💻')
            this.filterProvinces();
        }
    },

     computed: {
        ...mapState('searchBar',['citySelected','provinceSelected','emailCorralonesSelected','cityIdSelected']),
        ...mapState('generalFunctions',['error', 'loader', 'loader2', 'colorLoader']),
     }


}
</script>



<style scoped>
    .padding20Responsive {
        padding:0px;
    }

    @media screen and (max-width: 420px) {
        .padding20Responsive {
            padding:20px;
        }
    }
     .firstLetter {
        text-transform: lowercase!important;;
    }
    .firstLetter:first-letter {
        text-transform: uppercase!important;
    }


    .zIndexObservaciones {
        z-index:4;
        position:relative;
    }

    .buttonMoreInput {
        height:40px!important;
    }   

   .marginSection {
       margin-left:30px;
       margin-right:30px;
   }

  .closeSearchBarOnClick {
        background-color:transparent;
        position:absolute;
        top: 0;
        z-index:1;
        right: 0;
        bottom: 0;
        left: 0;
    }

    .searchBarContainer {
        max-width:760px;
        margin:auto;
        display:block;
        position:relative;
        cursor:pointer;
    }
    
    .searchBarCitys {
        color:rgb(44, 44, 44);
        background-color:rgb(240, 240, 240);
        border-radius:3px;
        display:block;
        margin:auto;
        position:absolute;
        z-index:6;
        padding-top:10px;
        padding-bottom:10px;
        width:100%;
        text-align:center;
           max-height:200px;
        overflow:auto;
    }

    .searchBarButton {
        color:#FFF!important;
    }

    .listCitys {
        margin-top:5px;
        padding-bottom:5px;
        border-bottom: 1px solid rgb(248, 248, 248);
    }

    .mb-0 {
        margin-bottom:0px!important;
    }

    .cuadradoInicioSesion {
        border-radius: 10px!important;
    }

    .buttonRequestBudget {
        width:100%!important;
        z-index: 5;
        height: 60px;
    }

    .card-2 {
    box-shadow: 0 0px 5px rgba(0,0,0,0.06), 0 1px 10px rgba(0,0,0,0.05);
    }


    /* Footer */
    /* Footer */

    .mt-12 {
        margin-top:80px!important;
    }

    .mb-12 {
        margin-bottom:100px!important;
    }


    /* Loader */
    /* Loader */

    .loaderCentrado {
        display:block !important;
        margin:auto !important;
        width:60px !important;
        margin-top:40px!important;
    }

    .textoCargando {
        color:#ff7850!important;
        font-weight:600!important; 
    }

    .fade-enter-active, .fade-leave-active {
    transition: opacity 1s;
    }
    .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
    }


    /* Colors text */
    /* Colors text */

    .colorPrimary {
        color:#8752aa!important;
    }

    .colorSecondary {
        color:#ff7850!important;
    }

    .colorTertiary {
        color:#ffc627!important;
    }

    .colorGrey {
        color:rgb(112, 112, 112)!important;
    }

    .colorSoftRed {
        color:rgb(219, 53, 53)!important;
    }


    .colorRed {
        color:red!important;
    }

    .colorYellow {
        color:rgb(233, 116, 21);
    }

    .colorSuccess {
        color:seagreen!important;
    }

    .colorSuccessTwo {
        color:#23d160!important;
    }   

    .colorWhite {
        color:rgb(255, 255, 255)!important;
    }


    /* Colors bg */
    /* Colors bg */

    .bgColorPrimary {
        background-color:#8752aa!important;
    }

    .bgColorSecondary {
        background-color:#ff7850!important;
    }

    .bgColorTertiary {
        background-color:#ffc627!important;
    }

    .bgColorSoftRed {
        background-color:#f64444!important;
    }

    .bgColorSoftBlue {
        background-color:#3d40fd!important;
    }


    /* Texto */
    /* Texto */

    .fontWeigth300 {
        font-weight:300!important;
    }

    .fontWeigth400 {
        font-weight:400!important;
    }

    .fontWeigth600 {
        font-weight:600!important;
    }

    .fontWeigth700 {
        font-weight:700!important;
    }


    /* Bordes */
    /* Bordes */

    .borderPrimary {
        border-color:#8752aa!important;
    }

    .borderSecondary {
        border-color:#ff7850!important;
    }

    .borderTertiary {
        border-color:#ffc627!important;
    }


    /* Botoness */
    /* Botones */


    .button:hover, .button.is-hovered {
        border-color: transparent!important;
        color: #363636;
    }

    /* Margins */
    /* Margins */

    .mt-1 {
        margin-top:10px!important;
    }

    .mb-1 {
        margin-bottom:10px!important;
    }


    .mt-2 {
        margin-top:20px!important;
    }

    .mb-2 {
        margin-bottom:20px!important;
    }

    .mb-3 {
        margin-bottom:35px!important;
    }

    .mb-5 {
        margin-bottom:50px!important;
    }

    /* Padding */
    /* Padding */

    .p-0 {
        padding:0px!important;
    }

    .p-1 {
        padding:20px!important;
    }

    .p-2 {
        padding:20px!important;
    }

    .p-3 {
        padding:30px!important;
    }

    .p-6 {
        padding:60px!important;
    }


    /* Width */
    /* Width */

    .w100 {
        width:100%!important;
    }

    .maxW500 {
        max-width:500px!important;
    }

    .maxW300 {
        max-width:300px!important;
    }

    .maxW200 {
        max-width:200px!important;
    }

    .maxW100 {
        max-width:100px!important;
    }

    .maxW50 {
        max-width:50px!important;
    }

    /* Input */
    /* Input */

    .ErrorInput {
        border: 1px solid rgb(255, 0, 0)!important;
        border-radius:6px!important;
        box-shadow: 0 0 0 0em rgb(255, 0, 0)!important;
        border-color: rgb(255, 6, 6)!important;
    }

    .ErrorInput:focus {
        border: 2px solid rgb(226, 134, 134)!important;
        border-radius:6px!important;
        box-shadow: 0 0 0 0em rgb(226, 134, 134)!important;
    
    }

    .NormalInput {  
        border: 1px solid rgb(248, 246, 246) !important;
        border-radius:4px!important;
        border-color: rgb(230, 230, 230) !important;
    }

    .NormalInput:focus {  
        border: 1px solid rgb(145, 21, 202) !important;
        border-radius:4px!important;
        border-color: rgb(145, 21, 202) !important;
    }


    .SuccessInput {  
        border: 1px solid seagreen !important;
        border-radius:4px!important;
        border-color: seagreen!important;
    }

    .SuccessInput:focus {  
        border: 1px solid seagreen!important;
        border-radius:4px!important;
        box-shadow: 0 0 0 0.125em rgba(30, 218, 86, 0.45)!important;
        border-color: seagreen!important;
    }


    .SuccessInputTwo {  
        border: 1px solid seagreen !important;
        border-radius:4px!important;
        border-color: seagreen!important;
        margin-left:5%;
    }

    .SuccessInputTwo:focus {  
        border: 1px solid seagreen!important;
        border-radius:4px!important;
        box-shadow: 0 0 0 0.125em rgba(30, 218, 86, 0.45)!important;
        border-color: seagreen!important;
        margin-left:5%;
    }


    .input:focus, .taginput .taginput-container.is-focusable:focus, .textarea:focus, .select select:focus, .is-focused.input, .taginput .is-focused.taginput-container.is-focusable, .is-focused.textarea, .select select.is-focused, .input:active, .taginput .taginput-container.is-focusable:active, .textarea:active, .select select:active, .is-active.input, .taginput .is-active.taginput-container.is-focusable, .is-active.textarea, .select select.is-active {
        border-color: transparent!important;
        box-shadow: 0 0 0 0em rgba(121, 87, 213, 0.25)!important;
    }

    .control .help.counter {
        display: none!important;
    }

    .input, .taginput .taginput-container.is-focusable, .textarea {
        -webkit-box-shadow: inset 0 1px 2px rgba(10, 10, 10, 0) !important;
        box-shadow: inset 0 1px 2px rgba(10, 10, 10, 0) !important;
    }

    .help.is-danger {
        display: none !important;
    }



</style>