<template>
<div>

  

                        <div class="searchBarContainer mt-1">
                            <div  v-if="hiddeInput === false">
                            <div style="display:block!important;margin:auto!important">
                            <b-field class="mb-0 inputMaterialesW60">
                                <b-input
                                    type="text"
                                    placeholder="Material"
                                    custom-class="inputMateriales"
                                    v-model="material8"
                                    autocomplete="off"
                                    @focus="setModalMaterial8(true) && setModalMaterial7(false)"  
                                   :change="$emit('clickedItem', material8)"                               
                                    >
                                </b-input>
                            </b-field>

                           <div class="inputCantidadW40 correctZindex is-inline-block">
          
                                <button class="correctZindex is-inline-block botonMasMenosMateriales bgColorPrimary"
                                v-on:click="quantity8 -= 1"
                                :disabled="quantity8 === 1"
                                >- 
                                </button> 

                            <b-field class="mb-0 is-inline-block widthDivInput">
                                    <b-input
                                        type="number"
                                        custom-class="inputCantidad100"
                                        v-model="quantity8"
                                        :change="$emit('quantity', quantity8)"   
                                        style="width:100%"
                                        >
                                    </b-input>
                            </b-field>
                            <button class="correctZindex is-inline-block botonMasMenosMateriales bgColorPrimary"
                            v-on:click="sum()">+</button>

                            </div>

                            <div v-if="filteredMaterials.length > 0 && modalMaterial8  && material8.length > 0" class="searchMaterialResponsive">
                                <ul class="searchBarCitys  inputMaterialesW60">
                                    <li class="listCitys letraFiltroMateriales"
                                        v-for="(filteredMaterial, index) in filteredMaterials" :key="index"
                                        @click="setMaterial(filteredMaterial)">{{ filteredMaterial }}</li>
                                </ul>
                            </div>
                       
                            </div>
                            </div>
         
                        </div>
                        
</div>
</template>



<script>
import { mapActions, mapState } from "vuex"
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'

export default {
    
    name: 'CmpMaterial1',
    data() {
        return {

            // Materiales
            quantity8: 1,
            material8: '',
            filteredMaterials: [],
            hiddeInput: false,
        }
    },
    mounted() {

        this.filterMaterials(); 

    },
    methods: {

        ...mapActions('sessions',['createUser']),
        ...mapActions('searchBar',['requestBudget']),
        ...mapActions('materialInputs',['setModalMaterial7','setModalMaterial8','setQuantity8']),


        sum() {
            const sumTotal = parseFloat(this.quantity8) + 1;
            this.quantity8 = sumTotal;
        },

        // Material Material Material Material
        // Material Material Material Material 

        filterMaterials() {
     

            if (this.material8.length == 0) {
                this.filteredMaterials = this.materials;
            }
      
            this.filteredMaterialsLowerCase = this.materials.filter(material => {
                return material.toLowerCase().includes(this.material8.toLowerCase());
            })

             this.filteredMaterials = this.filteredMaterialsLowerCase.slice(0, 6);

        },

        setMaterial(material) {
            this.material8 = material;
            this.setModalMaterial8(false);
        }     
     },
    watch: {

        material8() {
            this.filterMaterials();
        },

    },
    computed: {
        ...mapState('materialInputs',['materials']),
        ...mapState('materialInputs',['modalMaterial8']),
        ...mapState('searchBar',['citySelected']),
        ...mapState('generalFunctions',['error', 'loader', 'colorLoader']),
    }


}
</script>



<style scoped>

   .marginSection {
       margin-left:30px;
       margin-right:30px;
   }

  .closeSearchBarOnClick {
        background-color:transparent;
        position:absolute;
        top: 0;
        z-index:1;
        right: 0;
        bottom: 0;
        left: 0;
    }

    .searchBarContainer {
        max-width:760px;
        margin:auto;
        display:block;
        position:relative;
    }
    
    .searchBarCitys {
        color:rgb(44, 44, 44);
        background-color:rgb(240, 240, 240);
        border-radius:3px;
        display:block;
        margin:auto;
        position:absolute;
        z-index:6;
        padding-top:10px;
        padding-bottom:10px;
        width:100%;
        text-align:center;
    }

    .searchBarButton {
        color:#FFF!important;
    }

    .listCitys {
        margin-top:5px;
        padding-bottom:5px;
        border-bottom: 1px solid rgb(248, 248, 248);
        z-index:10;
    }

    .mb-0 {
        margin-bottom:0px!important;
    }

    .cuadradoInicioSesion {
        border-radius: 10px!important;
    }

    .buttonRequestBudget {
        width:100%!important;
        z-index: 5;
        height: 60px;
    }

    .card-2 {
    box-shadow: 0 0px 5px rgba(0,0,0,0.06), 0 1px 10px rgba(0,0,0,0.05);
    }


    /* Footer */
    /* Footer */

    .mt-12 {
        margin-top:80px!important;
    }

    .mb-12 {
        margin-bottom:100px!important;
    }


    /* Loader */
    /* Loader */

    .loaderCentrado {
        display:block !important;
        margin:auto !important;
        width:60px !important;
        margin-top:40px!important;
    }

    .textoCargando {
        color:#ff7850!important;
        font-weight:600!important; 
    }

    .fade-enter-active, .fade-leave-active {
    transition: opacity 1s;
    }
    .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
    }


    /* Colors text */
    /* Colors text */

    .colorPrimary {
        color:#8752aa!important;
    }

    .colorSecondary {
        color:#ff7850!important;
    }

    .colorTertiary {
        color:#ffc627!important;
    }

    .colorGrey {
        color:rgb(112, 112, 112)!important;
    }

    .colorSoftRed {
        color:rgb(219, 53, 53)!important;
    }


    .colorRed {
        color:red!important;
    }

    .colorYellow {
        color:rgb(233, 116, 21);
    }

    .colorSuccess {
        color:seagreen!important;
    }

    .colorSuccessTwo {
        color:#23d160!important;
    }   

    .colorWhite {
        color:rgb(255, 255, 255)!important;
    }


    /* Colors bg */
    /* Colors bg */

    .bgColorPrimary {
        background-color:#8752aa!important;
    }

    .bgColorSecondary {
        background-color:#ff7850!important;
    }

    .bgColorTertiary {
        background-color:#ffc627!important;
    }

    .bgColorSoftRed {
        background-color:#f64444!important;
    }

    .bgColorSoftBlue {
        background-color:#3d40fd!important;
    }


    /* Texto */
    /* Texto */

    .fontWeigth300 {
        font-weight:300!important;
    }

    .fontWeigth400 {
        font-weight:400!important;
    }

    .fontWeigth600 {
        font-weight:600!important;
    }

    .fontWeigth700 {
        font-weight:700!important;
    }


    /* Bordes */
    /* Bordes */

    .borderPrimary {
        border-color:#8752aa!important;
    }

    .borderSecondary {
        border-color:#ff7850!important;
    }

    .borderTertiary {
        border-color:#ffc627!important;
    }


    /* Botoness */
    /* Botones */


    .button:hover, .button.is-hovered {
        border-color: transparent!important;
        color: #363636;
    }

    /* Margins */
    /* Margins */

    .mt-1 {
        margin-top:10px!important;
    }

    .mb-1 {
        margin-bottom:10px!important;
    }


    .mt-2 {
        margin-top:20px!important;
    }

    .mb-2 {
        margin-bottom:20px!important;
    }

    .mb-3 {
        margin-bottom:35px!important;
    }

    .mb-5 {
        margin-bottom:50px!important;
    }

    /* Padding */
    /* Padding */

    .p-0 {
        padding:0px!important;
    }

    .p-1 {
        padding:20px!important;
    }

    .p-2 {
        padding:20px!important;
    }

    .p-3 {
        padding:30px!important;
    }

    .p-6 {
        padding:60px!important;
    }


    /* Width */
    /* Width */

    .w100 {
        width:100%!important;
    }

    .maxW500 {
        max-width:500px!important;
    }

    .maxW300 {
        max-width:300px!important;
    }

    .maxW200 {
        max-width:200px!important;
    }

    .maxW100 {
        max-width:100px!important;
    }

    .maxW50 {
        max-width:50px!important;
    }

    /* Input */
    /* Input */

    .ErrorInput {
        border: 1px solid rgb(255, 0, 0)!important;
        border-radius:6px!important;
        box-shadow: 0 0 0 0em rgb(255, 0, 0)!important;
        border-color: rgb(255, 6, 6)!important;
    }

    .ErrorInput:focus {
        border: 2px solid rgb(226, 134, 134)!important;
        border-radius:6px!important;
        box-shadow: 0 0 0 0em rgb(226, 134, 134)!important;
    
    }

    .NormalInput {  
        border: 1px solid rgb(248, 246, 246) !important;
        border-radius:4px!important;
        border-color: rgb(230, 230, 230) !important;
    }

    .NormalInput:focus {  
        border: 1px solid rgb(145, 21, 202) !important;
        border-radius:4px!important;
        border-color: rgb(145, 21, 202) !important;
    }


    .SuccessInput {  
        border: 1px solid seagreen !important;
        border-radius:4px!important;
        border-color: seagreen!important;
    }

    .SuccessInput:focus {  
        border: 1px solid seagreen!important;
        border-radius:4px!important;
        box-shadow: 0 0 0 0.125em rgba(30, 218, 86, 0.45)!important;
        border-color: seagreen!important;
    }


    .SuccessInputTwo {  
        border: 1px solid seagreen !important;
        border-radius:4px!important;
        border-color: seagreen!important;
        margin-left:5%;
    }

    .SuccessInputTwo:focus {  
        border: 1px solid seagreen!important;
        border-radius:4px!important;
        box-shadow: 0 0 0 0.125em rgba(30, 218, 86, 0.45)!important;
        border-color: seagreen!important;
        margin-left:5%;
    }


    .input:focus, .taginput .taginput-container.is-focusable:focus, .textarea:focus, .select select:focus, .is-focused.input, .taginput .is-focused.taginput-container.is-focusable, .is-focused.textarea, .select select.is-focused, .input:active, .taginput .taginput-container.is-focusable:active, .textarea:active, .select select:active, .is-active.input, .taginput .is-active.taginput-container.is-focusable, .is-active.textarea, .select select.is-active {
        border-color: transparent!important;
        box-shadow: 0 0 0 0em rgba(121, 87, 213, 0.25)!important;
    }

    .control .help.counter {
        display: none!important;
    }

    .input, .taginput .taginput-container.is-focusable, .textarea {
        -webkit-box-shadow: inset 0 1px 2px rgba(10, 10, 10, 0) !important;
        box-shadow: inset 0 1px 2px rgba(10, 10, 10, 0) !important;
    }

    .help.is-danger {
        display: none !important;
    }



</style>