<template>
    <section class="bgColorSoftGreyCorralon pb-12">
        <div class="">
            <CmpBudgeter />
            <CmpFooter />
        </div>

    </section>   
</template>

<script>
import CmpFooter from '@/components/footer/Footer.vue'
import CmpSlider from '@/components/home/cmpChildren/Slider.vue'
import CmpBudgeter from '@/components/requestBudget/cmpChildren/Budgeter.vue' 

export default {

  name: 'CmpHome',

  components: {
    CmpBudgeter,
    CmpFooter,
  },

  mounted () {
      this.goTop()
  },

 methods: {
         goTop() {
             window.scrollTo(0, 0);
        },
 }
}
</script>


<style>

    @import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700;800&display=swap');
    @import url('https://fonts.googleapis.com/css?family=Roboto+Condensed');
    @import url('https://fonts.googleapis.com/css2?family=Assistant:wght@200;300;400;600;700;800&display=swap');

    .mt-12 {
        margin-top:80px!important;
    }
    .pt-12 {
        padding-top:80px;
    }
        .pb-12 {
        padding-top:80px;
    }
   .letraFiltroMateriales {
        font-size:14px!important;
        padding:10px;
    }

    .searchMaterialResponsive {
        padding-left:0px;
    }

    @media (max-width:420px){
                .pb-12 {
        padding-top:1px;
    }
  .searchMaterialResponsive {
        padding-left:5px;
    }
    }


    .imgFormMaterials {
        z-index:5!important;
        position:relative;
    }

    .botonAgregarQuitarMaterial {
        background-color:#fff!important;
        border:0px;
        border-radius:33%;
        z-index:5;position:relative;
        cursor:pointer;
        outline:none;
        font-weight: 600;
        margin:auto;
        display:block;
        padding-top:5px;
        padding-left:10px;
        padding-right:10px;
        padding-bottom:10px;
    }
        .botonAgregarQuitarMaterial:hover {
        border:0px;
        background-color:#fff!important;
        color:#ff7850!important;
        border-radius:33%;
        z-index:5;position:relative;
        cursor:pointer;
        outline:none;
        font-weight: 600;
        margin:auto;
        display:block;
        margin-bottom:24px;
        padding-top:5px;
        padding-left:10px;
        padding-right:10px;
        padding-bottom:10px;
    }

    .colorIconMoreInput {
        color:#8752aa!important;
    }

    .colorIconMoreInput:hover {
        color:#ff7850!important;
    }

    .iconoMediano {
        font-size:45px
    }

    .barraSeparadora {
        width:50px;
        height:5px;
        margin:auto;
        display:block;
    }

    .sinOverflow {
        overflow-x: hidden !important;
    }

    .columns {
    margin-left: 0rem; 
    margin-right: 0rem; 
    margin-top: -0.75rem;
    }       
 

    /* Sombras */
    /* Sombras */   

    .card-1 {
    /*box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);*/
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);

    }

    .card-1:hover {
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
    }

    .card-2 {
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    }

    .card-3 {
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
    }

    .card-4 {
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
    }

    .card-5 {
    box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
    }


    /* Font */
    /* Font */   

    html, body {
        font-family: 'Open Sans', sans-serif;
    }

    #app {
        font-family: 'Open Sans', sans-serif;
    }

    .font1 {
        font-family: 'Open Sans', sans-serif;
    }

    .font2 {
        font-family: 'Assistant', sans-serif;
    }


    /* TextStyle */
    /* TextStyle */

    .lineHeigth0 {
        line-height: 0px;
    }

    .lineHeigth1 {
        line-height: 30px;
    }

     .letterSpacing05 {
        letter-spacing: 0.5px;
    }

     .letterSpacing1 {
        letter-spacing: 1px;
    }

     .letterSpacing2 {
        letter-spacing: 2px;
    }

    .letterSpacing3 {
        letter-spacing: 3px;
    }
    


    /* Colors text */
    /* Colors text */

    .colorPrimary {
        color:#8752aa!important;
    }

    .colorSecondary {
        color:#ff7850!important;
    }

    .colorTertiary {
        color:#ffc627!important;
    }

    .colorGrey {
        color:rgb(112, 112, 112)!important;
    }

    .colorBlack {
        color:rgb(24, 24, 24)!important;
    }

    .colorSoftRed {
        color:rgb(219, 53, 53)!important;
    }


    .colorRed {
        color:red!important;
    }

    .colorYellow {
        color:rgb(233, 116, 21);
    }

    .colorSuccess {
        color:seagreen!important;
    }

    .colorWhite {
        color:rgb(255, 255, 255)!important;
    }


    /* Colors bg */
    /* Colors bg */

    .bgColorPrimary {
        background-color:#8752aa!important;
    }

    .bgColorSecondary {
        background-color:#ff7850!important;
    }

    .bgColorTertiary {
        background-color:#ffc627!important;
    }

    .bgColorSoftRed {
        background-color:#f64444!important;
    }

    .bgColorSoftBlue {
        background-color:#3d40fd!important;
    }

    .bgColorSoftGreyCorralon {
        background-color:#f5f5f56b!important;
    }

    /* Texto */
    /* Texto */

    .fontWeigth300 {
        font-weight:300!important;
    }

    .fontWeigth400 {
        font-weight:400!important;
    }

    .fontWeigth600 {
        font-weight:600!important;
    }

    .fontWeigth700 {
        font-weight:700!important;
    }


    /* Bordes */
    /* Bordes */

    .borderPrimary {
        border-color:#8752aa!important;
    }

    .borderSecondary {
        border-color:#ff7850!important;
    }

    .borderTertiary {
        border-color:#ffc627!important;
    }


    /* Botoness */
    /* Botones */


    .button:hover, .button.is-hovered {
        border-color: transparent!important;
        color: #363636;
    }

    /* Margins */
    /* Margins */
   .m-0 {
        margin-top:0px!important;
    }

    .mt-1 {
        margin-top:10px!important;
    }

    .mb-1 {
        margin-bottom:10px!important;
    }


    .mt-2 {
        margin-top:20px!important;
    }

    .mb-2 {
        margin-bottom:20px!important;
    }


    .mt-8 {
        margin-top:80px!important;
    }

    .mb-8 {
        margin-bottom:80px!important;
    }


    /* Padding */
    /* Padding */
    .p-0 {
        padding:0px!important;        
    }

    .p-1 {
        padding:20px!important;
    }

    .p-2 {
        padding:20px!important;
    }

    .p-3 {
        padding:30px!important;
    }


    /* Width */
    /* Width */

    .w100 {
        width:100%!important;
    }

    .maxW300 {
        max-width:300px!important;
    }

    .maxW200 {
        max-width:200px!important;
    }

    .maxW100 {
        max-width:100px!important;
    }

    .maxW50 {
        max-width:50px!important;
    }


    /* Generales */
     /* Generales */
     
    .mtEspecial {
        margin-top:1px;
    }

</style>