<template>
    <div>
        <CmpRequestBudget />
    </div>
</template>

<script>
// Llamo al mapState para poder recibir los datos del Usuario y comprobar si existe una Sesion
import { mapState } from "vuex";
import CmpRequestBudget from '@/components/requestBudget/RequestBudget.vue'

export default {
    name: 'VRequestBudget',
    components: {
        CmpRequestBudget
    },
    computed:{

     }
}

</script>